@import url(https://fonts.googleapis.com/css?family=Abril+Fatface);
@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700);
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'PT Sans Narrow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #fff; */
  background: #f7f3f0;
  cursor: default;

  -webkit-scroll-snap-type: y mandatory;

      -ms-scroll-snap-type: y mandatory;

          scroll-snap-type: y mandatory;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Google fonts */

